<template>
  <div class="question-card w-100">
    <b-row no-gutters>
      <b-col cols="8" class="question-card__left-col">
        <svg
          @click="goToPreviousPage"
          class="back-arrow"
          width="32"
          height="25"
          viewBox="0 0 32 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.570872 11.1186L11.0923 0.571695L13.8454 0.571695L13.8454 3.33452L6.65089 10.5469L30.0508 10.5469L32 12.4999L30.0508 14.4531L6.65089 14.4531L13.8454 21.6654L13.8454 24.4284L12.47 25L11.0923 24.4284L0.570872 13.8816L0.570872 11.1186Z"
            fill="#FFCD02"
          />
        </svg>

        <p class="question-card__question">
          <!-- Heb je een plat of een schuin dak? -->
          Waar wil je de vloerisolatie laten aanbrengen?
        </p>

        <div class="row no-gutters">
          <div class="col-lg-10 col-12">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="radio-group-2"
                v-model="selected"
                :aria-describedby="ariaDescribedby"
                name="radio-sub-component"
              >
                <div class="check-item mt-3" @click="handleClick('4D1')">
                  <b-form-radio value="1"
                    >Gelijkvloers en/of tussenverdieping
                  </b-form-radio>
                </div>

                <div class="check-item mt-3" @click="handleClick('4D2')">
                  <b-form-radio value="2">In de kelder </b-form-radio>
                </div>
                <div class="check-item mt-3" @click="handleClick('4181')">
                  <b-form-radio value="4181">Op de zoldervloer </b-form-radio>
                </div>
              </b-form-radio-group>
            </b-form-group>
          </div>
        </div>
      </b-col>
      <b-col cols="4" class="p-0 question-card__right-col">
        <GoogleMapSection />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GoogleMapSection from "../GoogleMapSection.vue";
import { BModal, BButton } from "bootstrap-vue";

export default {
  name: "FourthD_Question",

  data() {
    return {
      selected: "",
    };
  },
  components: {
    GoogleMapSection,
  },
  props: {
    question: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  methods: {
    handleClick(val) {
      this.$emit("nextQuestion", val === "4181" ? "analyze" : val, val);
    },
    goToPreviousPage() {
      this.$emit("previousQuestion", "4");
    },
  },

  watch: {
    selected() {
      this.$emit("nextQuestion", "5", this.selected);
    },
  },
};
</script>

<style lang="scss" scoped>
.question-card {
  &__right-col {
    background-image: url(../../../assets/imgs/secondQuestion.png);
    background-size: 100% 100% !important;
  }
}
</style>
