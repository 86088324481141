<template>
  <div class="analyze-card">
    <p class="analyze-card__top-text">
      <span class="text-green">Belgischadvies.be</span> analyseert nu je
      gegevens..
    </p>
    <b-progress :max="100" :value="value">
      <b-progress-bar :value="value"></b-progress-bar>
    </b-progress>

    <p class="analyze-card__progress-text">
      {{ this.details.house_number.replace("-", "") }} {{ address }}
    </p>

    <!-- <p
      class="analyze-card__progress-text"
      v-if="address.results && address.results.length"
    >
      {{ address?.results[0]?.formatted_address }}
    </p> -->

    <div class="d-flex mt-1 check-item" v-if="showCheckItem1">
      <svg
        class="mr-1"
        width="20"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5 0.32605C5.88442 0.32605 0.5 5.71048 0.5 12.326C0.5 18.9416 5.88442 24.326 12.5 24.326C19.1156 24.326 24.5 18.9416 24.5 12.326C24.5 5.71048 19.1156 0.32605 12.5 0.32605ZM12.5 2.32605C18.0347 2.32605 22.5 6.79136 22.5 12.326C22.5 17.8607 18.0347 22.326 12.5 22.326C6.9653 22.326 2.5 17.8607 2.5 12.326C2.5 6.79136 6.9653 2.32605 12.5 2.32605ZM17.4492 8.32605L11.1992 14.576L7.94922 11.326L6.55078 12.7264L11.1992 17.3768L18.8496 9.72644L17.4492 8.32605Z"
          fill="#0FBC00"
        />
      </svg>
      <p class="m-0 analyze-card__check">
        Jouw woning komt <span class="text-green">in aanmerking</span> voor
        isolatie
      </p>
    </div>
    <div class="d-flex mt-1 check-item" v-if="showCheckItem2">
      <svg
        class="mr-1"
        width="20"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5 0.32605C5.88442 0.32605 0.5 5.71048 0.5 12.326C0.5 18.9416 5.88442 24.326 12.5 24.326C19.1156 24.326 24.5 18.9416 24.5 12.326C24.5 5.71048 19.1156 0.32605 12.5 0.32605ZM12.5 2.32605C18.0347 2.32605 22.5 6.79136 22.5 12.326C22.5 17.8607 18.0347 22.326 12.5 22.326C6.9653 22.326 2.5 17.8607 2.5 12.326C2.5 6.79136 6.9653 2.32605 12.5 2.32605ZM17.4492 8.32605L11.1992 14.576L7.94922 11.326L6.55078 12.7264L11.1992 17.3768L18.8496 9.72644L17.4492 8.32605Z"
          fill="#0FBC00"
        />
      </svg>
      <p class="m-0 analyze-card__check">
        Jij <span class="text-green">bespaart tot 40%</span> met het vergelijken
        van offertes

        <!-- <span class="text-green" v-if="responses.length == 1">tot 15%</span> -->
        <!-- <span class="text-green" v-else-if="responses.length >= 2"
          >tot 30%</span
        >  -->
        <!-- <span class="text-green" v-else>tot 40%</span> -->
      </p>
    </div>
    <div class="d-flex mt-1 check-item" v-if="showCheckItem3">
      <svg
        class="mr-1"
        width="20"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5 0.32605C5.88442 0.32605 0.5 5.71048 0.5 12.326C0.5 18.9416 5.88442 24.326 12.5 24.326C19.1156 24.326 24.5 18.9416 24.5 12.326C24.5 5.71048 19.1156 0.32605 12.5 0.32605ZM12.5 2.32605C18.0347 2.32605 22.5 6.79136 22.5 12.326C22.5 17.8607 18.0347 22.326 12.5 22.326C6.9653 22.326 2.5 17.8607 2.5 12.326C2.5 6.79136 6.9653 2.32605 12.5 2.32605ZM17.4492 8.32605L11.1992 14.576L7.94922 11.326L6.55078 12.7264L11.1992 17.3768L18.8496 9.72644L17.4492 8.32605Z"
          fill="#0FBC00"
        />
      </svg>
      <p class="m-0 analyze-card__check">
        Jij hebt

        <span class="text-green">recht op 30% premie </span>
        in {{ new Date().getFullYear() }}
      </p>
    </div>
    <div class="d-flex mt-1 check-item" v-if="showCheckItem4">
      <svg
        class="mr-1"
        width="20"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5 0.32605C5.88442 0.32605 0.5 5.71048 0.5 12.326C0.5 18.9416 5.88442 24.326 12.5 24.326C19.1156 24.326 24.5 18.9416 24.5 12.326C24.5 5.71048 19.1156 0.32605 12.5 0.32605ZM12.5 2.32605C18.0347 2.32605 22.5 6.79136 22.5 12.326C22.5 17.8607 18.0347 22.326 12.5 22.326C6.9653 22.326 2.5 17.8607 2.5 12.326C2.5 6.79136 6.9653 2.32605 12.5 2.32605ZM17.4492 8.32605L11.1992 14.576L7.94922 11.326L6.55078 12.7264L11.1992 17.3768L18.8496 9.72644L17.4492 8.32605Z"
          fill="#0FBC00"
        />
      </svg>
      <p class="m-0 analyze-card__check">
        Wij hebben <span class="text-green">4 beschikbare offertes</span> voor
        jou
      </p>
    </div>

    <div class="bottom-item" v-if="showBottomItem">
      <p class="m-0 analyze-card__bottom-text mr-2">
        We sturen je nu door om de woningscan af te ronden..
      </p>
      <img src="@/assets/imgs/arrow-right.png" class="mr-2" alt="arrow" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AnalyzingSection",
  props: {
    responses: Array,
  },
  data() {
    return {
      value: 0,
      showCheckItem1: false,
      showCheckItem2: false,
      showCheckItem3: false,
      showCheckItem4: false,
      showBottomItem: false,
      responses: this.responses,
    };
  },
  props: {
    details: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleNextQuestion() {
      this.$emit("nextQuestion", "form");
    },
  },
  mounted() {
    const interval = setInterval(() => {
      if (this.value < 20) {
        this.value += 1;
      } else if (!this.showCheckItem1) {
        this.showCheckItem1 = true;
        this.value = 20;
      } else if (this.value < 40) {
        this.value += 1;
      } else if (!this.showCheckItem2) {
        this.showCheckItem2 = true;
        this.value = 40;
      } else if (this.value < 60) {
        this.value += 1;
      } else if (!this.showCheckItem3) {
        this.showCheckItem3 = true;
        this.value = 60;
      } else if (this.value < 80) {
        this.value += 1;
      } else if (!this.showCheckItem4) {
        this.showCheckItem4 = true;
        this.value = 80;
      } else if (this.value < 100) {
        this.value += 1;
      } else if (!this.showBottomItem) {
        this.showBottomItem = true;
        this.value = 100;
        clearInterval(interval);
        setTimeout(() => {
          // perform your function here
          this.$emit("nextQuestion", "form");
        }, 3000);
      }
    }, 60);
  },
};
</script>

<style lang="scss" scoped>
.analyze-card {
  border-radius: 30px;
  filter: drop-shadow(0 0 13.5px rgba(0, 0, 0, 0.21));
  background-color: #ffffff;
  padding: 50px;
  color: #1d1729;

  .progress {
    // height: 20px;
    height: 20px;
    border-radius: 14px;
    background-color: #ffffff;
    border: 1px solid #cccbcb;
  }

  .progress-bar {
    height: 20px;
    border-radius: 14px;
    background-color: #5cba01;
  }

  .text-green {
    color: #5cba01 !important;
  }

  &__top-text {
    // font-size: 45px;
    font-size: 35px;
    font-weight: 800;
  }

  &__progress-text {
    // font-size: 22px;
    font-size: 18px;
    font-weight: 800;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 40px;
  }

  &__check {
    font-size: 18px;
    font-weight: 700;
  }

  &__bottom-text {
    font-size: 24px;
    font-weight: 800;
  }

  .check-item {
    img {
      width: 15px;
      height: 15px;
    }
    display: flex;
    // align-items: baseline;
  }

  .bottom-item {
    display: flex;
    align-items: center;
    margin-top: 35px;
    img {
      width: 35px;
      height: 30px;
    }
  }

  @media (max-width: 991.99px) {
    padding: 40px;
    &__top-text {
      font-size: 30px;
    }

    &__progress-text {
      font-size: 16px;
      margin-bottom: 35px;
    }

    &__check {
      font-size: 16px;
    }

    &__bottom-text {
      font-size: 20px;
    }

    .bottom-item {
      margin-top: 30px;
      img {
        width: 30px;
        height: 25px;
      }
    }
  }

  @media (max-width: 767.99px) {
    padding: 30px;

    &__top-text {
      font-size: 25px;
    }

    &__progress-text {
      font-size: 14px;
      margin-bottom: 30px;
    }

    &__check {
      font-size: 14px;
    }

    &__bottom-text {
      font-size: 18px;
    }

    .bottom-item {
      margin-top: 25px;
      img {
        width: 25px;
        height: 20px;
      }
    }
  }

  @media (max-width: 575.99px) {
    padding: 30px 25px;
    .progress {
      height: 20px;
    }
    &__top-text {
      font-size: 22px;
    }

    &__progress-text {
      font-size: 12px;
      margin-bottom: 25px;
    }

    &__check {
      font-size: 12px;
    }

    &__bottom-text {
      font-size: 16px;
    }

    .bottom-item {
      margin-top: 20px;
      img {
        width: 23px;
        height: 18px;
      }
    }

    .check-item {
      img {
        width: 12px;
        height: 12px;
      }
    }
  }

  @media (max-width: 481.99px) {
    padding: 25px 20px;
    &__top-text {
      font-size: 18px;
    }

    &__progress-text {
      font-size: 13px;
      margin-bottom: 25px;
    }

    &__check {
      font-size: 14px;
    }

    &__bottom-text {
      font-size: 14px;
    }

    .bottom-item {
    }
  }
}
</style>
