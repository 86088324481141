<template>
  <div class="questions-section">
    <!-- Modal -->
    <b-modal
      v-model="showModal"
      id="kk"
      centered
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      ok-only
      ok-variant="warning"
      ok-title="GA VERDER"
      class="border border-none"
    >
      <div class="py-5">
        <h5 class="text-center">
          We hebben nog enkele vervolgvragen die van toepassing zijn op je
          gekozen isolatievormen
        </h5>
      </div>
    </b-modal>
    <!-- Ended -->
    <b-container>
      <b-row
        class="justify-content-center"
        v-if="
          // !(question === '2C' || question === 'form' || question === 'analyze')
          question === '1' ||
          question === '2' ||
          question === 2 ||
          question === '3' ||
          question === '4'
        "
      >
        <b-col cols="12" xl="9">
          <b-progress :max="max">
            <b-progress-bar
              :value="value"
              :label="`${Math.ceil((value / max) * 100)}%`"
            ></b-progress-bar>
          </b-progress>
        </b-col>
      </b-row>

      <b-row class="justify-content-center mt-4">
        <b-col xl="9" cols="12" v-if="question == '1'">
          <FirstQuestion
            :question="question"
            :max="max"
            :details="details"
            @nextQuestion="nextQuestion"
            @previousPage="goToPrevious"
          />
        </b-col>
        <b-col xl="9" cols="12" v-else-if="question == '2'">
          <SecondQuestion
            :question="question"
            :max="max"
            @nextQuestion="nextQuestion"
            @previousQuestion="previousQuestion"
          /> </b-col
        ><b-col xl="9" cols="12" v-else-if="question == '2B'">
          <SecondBQuestion
            :question="question"
            :max="max"
            @nextQuestion="nextQuestion"
            @previousQuestion="previousQuestion"
          /> </b-col
        ><b-col xl="9" cols="12" v-else-if="question == '2C'">
          <SecondCQuestion
            :question="question"
            :max="max"
            @nextQuestion="nextQuestion"
            @previousQuestion="previousQuestion"
          />
        </b-col>
        <b-col xl="9" cols="12" v-else-if="question == '3'">
          <ThirdQuestion
            :question="question"
            :max="max"
            @nextQuestion="nextQuestion"
            @previousQuestion="previousQuestion"
          />
        </b-col>
        <b-col xl="9" cols="12" v-else-if="question == '4'">
          <NewFourthQuestion
            :question="question"
            :max="max"
            @nextQuestion="nextQuestion"
            @previousQuestion="previousQuestion"
          />
        </b-col>
        <b-col xl="9" cols="12" v-else-if="question == '4A'">
          <FourthA1_Question
            :question="question"
            :max="max"
            @nextQuestion="nextQuestion"
            @previousQuestion="previousQuestion"
          />
        </b-col>
        <b-col xl="9" cols="12" v-else-if="question == '4A2'">
          <FourthA2_Question
            :question="question"
            :max="max"
            @nextQuestion="nextQuestion"
            @previousQuestion="previousQuestion"
          />
        </b-col>
        <b-col xl="9" cols="12" v-else-if="question == '4A3'">
          <FourthA3_Question
            :question="question"
            :max="max"
            @nextQuestion="nextQuestion"
            @previousQuestion="previousQuestion"
          />
        </b-col>
        <b-col xl="9" cols="12" v-else-if="question == '4A4'">
          <FourthA4_Question
            :question="question"
            :max="max"
            @nextQuestion="nextQuestion"
            @previousQuestion="previousQuestion"
          /> </b-col
        ><b-col xl="9" cols="12" v-else-if="question == '4B'">
          <FourthB1_Question
            :question="question"
            :max="max"
            @nextQuestion="nextQuestion"
            @previousQuestion="previousQuestion"
          />
        </b-col>
        <b-col xl="9" cols="12" v-else-if="question == '4C'">
          <FourthC1_Question
            :question="question"
            :text_answers="text_answers"
            :max="max"
            :details="details"
            @nextQuestion="nextQuestion"
            @previousQuestion="previousQuestion"
          />
        </b-col>
        <b-col xl="9" cols="12" v-else-if="question == '4C2'">
          <FourthC2_Question
            :question="question"
            :max="max"
            @nextQuestion="nextQuestion"
            @previousQuestion="previousQuestion"
          />
        </b-col>
        <b-col xl="9" cols="12" v-else-if="question == '4D'">
          <FourthD_Question
            :question="question"
            :max="max"
            @nextQuestion="nextQuestion"
            @previousQuestion="previousQuestion"
          />
        </b-col>
        <b-col xl="9" cols="12" v-else-if="question == '4D1'">
          <FourthD1_Question
            :question="question"
            :max="max"
            @nextQuestion="nextQuestion"
            @previousQuestion="previousQuestion"
          />
        </b-col>
        <b-col xl="9" cols="12" v-else-if="question == '4D2'">
          <FourthD2_Question
            :question="question"
            :max="max"
            @nextQuestion="nextQuestion"
            @previousQuestion="previousQuestion"
          />
        </b-col>
      </b-row>

      <b-row class="justify-content-center mt-4" v-if="question == 'analyze'">
        <b-col xl="9" cols="12">
          <AnalyzingSection
            @nextQuestion="nextQuestion"
            :responses="responses"
            :details="details"
          />
        </b-col>
      </b-row>
      <b-row class="justify-content-center mt-4" v-if="question == 'form'">
        <b-col xl="9" cols="12">
          <FormSection
            :answers="answers"
            :text_answers="text_answers"
            :details="details"
            @nextQuestion="nextQuestion"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import FirstQuestion from "./Questions/First_Question.vue";
import SecondQuestion from "./Questions/Second_Question.vue";
import SecondBQuestion from "./Questions/SecondB_Question.vue";
import SecondCQuestion from "./Questions/SecondC_Question.vue";
import ThirdQuestion from "./Questions/Third_Question.vue";
// import FourthQuestion from "./Questions/Fourth_Question.vue";
import FourthA1_Question from "./Questions/FourthA1_Question.vue";
import FourthA2_Question from "./Questions/FourthA2_Question.vue";
import FourthA3_Question from "./Questions/FourthA3_Question.vue";
import FourthA4_Question from "./Questions/FourthA4_Question.vue";
import FourthB1_Question from "./Questions/FourthB1_Question.vue";
import FourthC1_Question from "./Questions/FourthC1_Question.vue";
import FourthC2_Question from "./Questions/FourthC2_Question.vue";
import FourthD_Question from "./Questions/FourthD_Question.vue";
import FourthD1_Question from "./Questions/FourthD1_Question.vue";
import FourthD2_Question from "./Questions/FourthD2_Question.vue";
import NewFourthQuestion from "./Questions/NewFourthQuestion.vue";
// import FifthQuestion from "./Questions/Fifth_Question.vue";
// import SixthQuestion from "./Questions/Sixth_Question.vue";
import AnalyzingSection from "./Analyzing_Section.vue";
import FormSection from "./Form_isolate.vue";

export default {
  name: "Questions_Section",
  components: {
    FirstQuestion,
    SecondQuestion,
    SecondBQuestion,
    SecondCQuestion,
    ThirdQuestion,
    AnalyzingSection,
    FormSection,
    NewFourthQuestion,
    FourthA1_Question,
    FourthA2_Question,
    FourthA3_Question,
    FourthA4_Question,
    FourthB1_Question,
    FourthC1_Question,
    FourthC2_Question,
    FourthD_Question,
    FourthD1_Question,
    FourthD2_Question,
  },

  data() {
    return {
      showModal: false,
      ans: "",
      question: "1",
      // area: "",
      value: 1,
      text_answers: "",
      max: 4,
      responses: "",
      followUpQuestions: [],
      select4DOptions: [],
      currentFollowUpQuestions: "",
      details: {
        language: "nl_BE",
        site_custom_name: "belgischadvies_isolatie",
        site_custom_url: "https://belgischadvies.be/isolatie",
        publisher_id: "",
        site_subid: "",
        house_number: "",
        zip: "",
        gender: "",
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
        text_answers: "",
      },
      answers: ["4169"],
    };
  },
  props: {
    postdata: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.details.publisher_id = this.$route.query.site;
    this.details.site_subid = this.$route.query.click;
  },
  methods: {
    nextQuestion(que, ans) {
      console.log("que: ", que);
      console.log("ans: ", ans);
      if (que === "followUpQuestion") {
        this.followUpQuestions = ans;
      }

      if (que === "analyze" && this.followUpQuestions.length > 0) {
        const index = this.followUpQuestions.indexOf(
          this.currentFollowUpQuestions
        );
        const nextQuestion = this.followUpQuestions[index + 1] || "analyze";

        this.question = nextQuestion;
        this.currentFollowUpQuestions = nextQuestion;
      } else {
        if (Array.isArray(ans) && que !== "analyze" && que !== "4A2") {
          this.showModal = true;

          this.question = ans[0];
          this.currentFollowUpQuestions = ans[0];
        } else {
          this.ans = ans;
          this.question = this.value = que;
        }
      }
      if (
        ans &&
        que !== "followUpQuestion" &&
        !["followUpQuestion", "2B", "2C", "4D1", "4D2", ""].includes(ans)
      ) {
        if (Array.isArray(ans)) {
          // If ans is an array, push its elements individually into this.answers
          this.answers.push(...ans);
        } else {
          // If ans is not an array, push it as is
          this.answers.push(ans);
        }
      }
    },

    handleNextSelected(arr) {
      this.answers = this.answers.concat(arr);
      this.responses = arr;
      if (this.answers.includes("dak isoleren")) {
        this.question = "6";
        this.answers = this.answers.filter((answ) => answ !== "dak isoleren");
      } else {
        this.question = "analyze";
      }
    },
    goToPrevious() {
      this.$emit("previousPage");
    },
    previousQuestion(que) {
      console.log("que: ", que);
      const filters = {
        2: ["4356", "4359", "4527"],
        3: ["4362", "4365", "4368"],
        "4A": ["4330", "4371", "4383", "4374", "4377", "4386", "4380"],
        "4A2": ["4342", "4339"],
        "4A3": ["4348", "4345"],
        "4B": ["4324", "4321", "4318", "4327"],
        "4A4": ["4354", "4351"],
        "4C": ["1641"],
        "4C2": ["4172", "4175", "4178", "4181"],
        "4D1": ["4312", "4386"],
        "4D": ["4181", "4386", "4312", "4315", "4389"],
        5: ["4229", "4232", "4235"],
        6: ["4297", "4300"],
      };

      if (que === "4" && this.currentFollowUpQuestions) {
        const index = this.followUpQuestions.indexOf(
          this.currentFollowUpQuestions
        );

        const preQuestion = this.followUpQuestions[index - 1] || "4";

        const mapping = {
          "4A": "4A4",
          "4C": "4C2",
          "4B": "4B",
        };

        if (preQuestion in mapping) {
          this.question = mapping[preQuestion];
          this.currentFollowUpQuestions = mapping[preQuestion];
          this.answers = this.answers.filter(
            (answ) => !filters[mapping[preQuestion]].includes(answ)
          );
        } else {
          this.question = preQuestion;
          this.currentFollowUpQuestions = preQuestion;
          if (preQuestion in filters) {
            this.answers = this.answers.filter(
              (answ) => !filters[preQuestion].includes(answ)
            );
          }
        }
      } else {
        this.question = que;
        this.currentFollowUpQuestions = que;
        if (que in filters) {
          this.answers = this.answers.filter(
            (answ) => !filters[que].includes(answ)
          );
        }
      }
      this.value = que;
    },
  },
  mounted() {
    if (this.postdata?.typePost) {
      this.question = "1";
      this.details.house_number = this.postdata.house_number;
      this.details.zip = this.postdata.zip;
    }
  },
  watch: {
    // "details.house_number"(newVal, oldVal) {
    //   this.getStreetsByZip({ zip: this.details.zip });
    // },
    "details.zip"(newVal, oldVal) {
      this.getAddress({
        zip: this.details.zip,
        house_number: this.details.house_number,
        street: this.details.street,
      });
    },
    "details.text_answers"(newVal, oldVal) {
      console.log("Data", newVal);
    },
    answers: {
      handler(newAnswers, oldAnswers) {
        console.log("Answers changed:", newAnswers);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.questions-section {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #fff5cc;

  .progress {
    height: 20px;
    border-radius: 14px;
    background-color: #ffffff;
  }

  .progress-bar {
    height: 20px;
    border-radius: 14px;
    background-color: #5cba01;
  }
}
</style>
